import React from 'react'
import {Route} from 'react-router-dom'

import {CountriesPage} from './Pages/CountriesPage'
import {LandingPage} from './Pages/LandingPage'
import {LegalDocumentPage} from './Pages/LegalDocumentPage'
import {LicensesPage} from './Pages/LicensesPage'
import {MobileHeadlessDocumentPage} from './Pages/mobile/MobileHeadlessDocumentPage'
import {MobileLegalAcceptancePage} from './Pages/mobile/MobileLegalAcceptancePage'
import {routes} from './routes'

export const Router: React.FC = () => {
  return (
    <>
      <Route path={[routes.LandingPage]} exact render={() => <LandingPage />} />
      <Route path={[routes.DocumentPerCountryPage]} exact render={() => <CountriesPage />} />
      <Route path={[routes.DocumentPage]} render={() => <LegalDocumentPage />} />
      <Route path={[routes.LicensesPage]} render={() => <LicensesPage />} />
      <Route
        path={[routes.MobileLegalAcceptancePage]}
        exact
        render={() => <MobileLegalAcceptancePage />}
      />
      <Route
        path={[routes.MobileHeadlessDocumentPage]}
        exact
        render={() => <MobileHeadlessDocumentPage />}
      />
    </>
  )
}
