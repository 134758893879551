import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import {withTheme} from '@hconnect/uikit'
import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CssBaseline as CssBaselineMui, CircularProgress} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import i18next from 'i18next'
import {identity} from 'lodash'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider} from 'react-i18next'
import {QueryClient, QueryClientProvider} from 'react-query'

import {api} from './api'
import {App} from './App'
import Localization from './Localization'
import {Head} from './meta/Head'

import 'core-js/stable'

import 'regenerator-runtime/runtime'
import {BrowserRouter, HashRouter} from 'react-router-dom'

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

void Localization()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1920
  }
}

// Note: for unknown reason in unit test overrideDefaultTheme is undefined
// therefore it will be defaulted to identity function
const legalTheme = (overrideHConnectTheme ?? identity)({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            background: 'linear-gradient(90deg, #0f4b73 0%, #18699f 40%,#18699f 60%, #0f4b73 100%)'
          }
        }
      }
    }
  },
  breakpoints: customBreakpoints
})

const render = (Component) => {
  const ThemedApp = withTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh',
            background: 'linear-gradient(90deg, #0f4b73 0%, #18699f 40%,#18699f 60%, #0f4b73 100%)'
          }
        }
      }
    }
  })(Component)

  const usesHtmlFile = /\.html/.test(window.location.pathname)
  const SelectedRouter = usesHtmlFile ? HashRouter : BrowserRouter

  ReactDOM.render(
    <Suspense fallback={<CircularProgress />}>
      <ApiProvider secureApi={null} publicApi={api}>
        <ThemeProvider theme={legalTheme}>
          <CssBaselineMui />
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18next}>
              <HelmetProvider>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <SelectedRouter>
                  <Head />
                  <ThemedApp />
                </SelectedRouter>
              </HelmetProvider>
            </I18nextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ApiProvider>
    </Suspense>,
    document.getElementById('root')
  )
}

render(App)
